import { useEffect, useState } from "react";
import { 
  PageHeader, 
  Button,
  Card,
  Table,
  message,
  Menu,
  Dropdown,
  Drawer,
  Form,
  Input,
  Checkbox,
  Modal,
  Spin
} from 'antd';
import { 
  useSearchParams,
  useNavigate 
} from "react-router-dom";
import { 
  UnorderedListOutlined, 
  DownOutlined,
  ExclamationCircleOutlined 
} from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import BaseLayout from "../../components/BaseLayout";
import useLoading from "../../hooks/useLoading";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import spuServices from "../../services/spu";

const CheckboxGroup = Checkbox.Group;

export default function PermissionsList() {
  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  let page: number = Number(searchParams.get("page")) || 1;
  let pageSize: number = Number(searchParams.get("pageSize")) || 20;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  // 弹窗
  const [typeModal, setTypeModal] = useState('add')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [id, setID] = useState('')
  const [name, setName] = useState('')
  const [account, setAccount] = useState('')
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  // 选择框
  const [plainOptions, setPlainOptions] = useState<Array<any>>([])
  const [defaultCheckedList, setDefaultCheckedList] = useState<Array<any>>([])
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [plainOptionsTwo, setPlainOptionsTwo] = useState<Array<any>>([])
  const [defaultCheckedListTwo, setDefaultCheckedListTwo] = useState<Array<any>>([])
  const [checkedListTwo, setCheckedListTwo] = useState<CheckboxValueType[]>(defaultCheckedListTwo);
  const [indeterminateTwo, setIndeterminateTwo] = useState(false);
  const [checkAllTwo, setCheckAllTwo] = useState(false);

  const columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
    },
    {
      key: "name",
      title: t("supplierList.columns.name"),
      dataIndex: "name",
    },
    {
      key: "account",
      title: t("crm.form.loginName"),
      dataIndex: "account",
    },
    {
      key: "mobile",
      title: t("system.form.mobile"),
      dataIndex: "mobile",
    },
    {
      key: "action",
      title: t("skuList.columns.action"),
      dataIndex: "action",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: any) => {
        const menu = (
          <Menu onClick={(param:any) => hanleMenuClick(param, record)}>
            <Menu.Item key="edit">{t("spuList.action.edit")}</Menu.Item>
            <Menu.Item key="delete">{t("spuList.action.delete")}</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button type="text" icon={<UnorderedListOutlined />}>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      }
    },
  ]

  useEffect(() => {
    onPageChange()
  }, [page, pageSize])

  // 列表
  const getData = async () => {
    try {
      const result = await spuServices.getAdminUsers({
        page: page,
        pageSize: pageSize,
      })

      const { response } = result.data

      if (response) {
        const { total, list } = response;
        
        setTotal(total);
        setDataSource(
          list.map((item: any) => ({
            id: item.id,
            name: item.name,
            account: item.account,
            mobile: item.mobile
          }))
        )
      }
      
    } catch (error) {
      message.error("Error")
    }
  }

  // 详情
  const getInfo = async (id?: number) => {
    try {
      setModalLoading(true)

      const result = await spuServices.getAdminUsersInfo(id ? id : '')

      const { response } = result.data

      if (response) {
        const { info, group, store } = response;
        
        setPlainOptions(
          group.map((item: any) => ({
            label: item.name,
            value: item.id.toString()
          }))
        )

        setPlainOptionsTwo(
          store.map((item: any) => ({
            label: item.name,
            value: item.id.toString()
          }))
        )

        if (info) {
          const groupID = info.groupID && info.groupID.split(',') || []
          const storeID = info.storeID && info.storeID.split(',') || []

          setCheckedList(groupID)
          setCheckAll(groupID.length === group.length)
          setIndeterminate(groupID.length < group.length && groupID.length > 0)

          setCheckedListTwo(storeID)
          setCheckAllTwo(storeID.length === store.length)
          setIndeterminateTwo(storeID.length < store.length && storeID.length > 0)
        }
      }

      setModalLoading(false)
    } catch (error) {
      message.error("Error")
      setModalLoading(false)
    }
  }

  const [onPageChange, loading] = useLoading(getData);

  // 分页
  const handlePageChange = (page: number, pageSize?: number) => {
    setSearchParams({
      page: String(page),
      pageSize: pageSize ? String(pageSize) : "20",
    });
  }

  const handleSearch = () => {
    setSearchParams({
      page: '1'
    })

    getData()
  }

  const onHandleAdd = () => {
    setIsModalVisible(true)

    getInfo()

    setName('')
    setAccount('')
    setMobile('')
    setPassword('')
    setPasswordConfirmation('')
    setCheckedList([])
    setCheckedListTwo([])
    setIndeterminate(false)
    setIndeterminateTwo(false)
    setCheckAll(false)
    setCheckAllTwo(false)

    setTypeModal('add')
  }
  
  const hideIsModalVisible = async () => {
    setIsModalVisible(false);

    await getData()
  }

  const handleInputChange = (type: string, e: any) => {
    if (type === 'name') {
      setName(e.target.value)
    } else if (type === 'account') {
      setAccount(e.target.value)
    } else if (type === 'mobile') {
      setMobile(e.target.value)
    } else if (type === 'password') {
      setPassword(e.target.value)
    } else {
      setPasswordConfirmation(e.target.value)
    }
  }


  // 单选
  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onChangeTwo = (list: CheckboxValueType[]) => {
    setCheckedListTwo(list);
    setIndeterminateTwo(!!list.length && list.length < plainOptionsTwo.length);
    setCheckAllTwo(list.length === plainOptionsTwo.length);
  };

  // 多选
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions.map((item: any) => item.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onCheckAllChangeTwo = (e: CheckboxChangeEvent) => {
    setCheckedListTwo(e.target.checked ? plainOptionsTwo.map((item: any) => item.value) : []);
    setIndeterminateTwo(false);
    setCheckAllTwo(e.target.checked);
  };

  const onSubmit = async () => {
    try {
      let params = {
        id: id,
        name: name,
        account: account,
        mobile: mobile,
        password: password,
        passwordConfirmation: passwordConfirmation,
        groupID: checkedList,
        storeID: checkedListTwo
      }

      if (!name) {
        message.warning(t("system.message.warningName"))

        return
      }

      if (!account) {
        message.warning(t("crm.message.warningAccount"))

        return
      }

      if (!password && typeModal === 'add') {
        message.warning(t("crm.message.warningPassword"))

        return
      }

      if (!passwordConfirmation && typeModal === 'add') {
        message.warning(t("crm.message.warningPasswordConfirmation"))

        return
      }

      if (password !== passwordConfirmation && typeModal === 'add') {
        message.warning(t("crm.message.warningPasswordVerify"))

        return
      }

      if (typeModal === 'add') {
        const result = await spuServices.addAdminUsers(params)

        const { error } = result.data

        if (error) {
          message.error(error.message)
        } else {
          message.success(t("crm.message.successAdd"))
        }
      } else {
        const result = await spuServices.updateAdminUsers(params)

        const { error } = result.data

        if (error) {
          message.error(error.message)
        } else {
          message.success(t("crm.message.successEdit"))
        }
      }

      hideIsModalVisible()
    } catch (error) {
      message.error("Error")
    }
  }

  const hanleMenuClick = (param: any, record: any) => {
    const {
      key
    } = param

    const {
      id: rId,
      name: rName,
      account: rAccount,
      mobile: rMobile
    } = record

    if (key === 'edit') {
      setIsModalVisible(true)
      setTypeModal('edit')

      getInfo(rId)
      setID(rId)
      setName(rName)
      setAccount(rAccount)
      setMobile(rMobile)
      setPassword('')
      setPasswordConfirmation('')
    }
    
    if (key === 'delete') {
      Modal.confirm({
        title: t("crm.message.warningDelete"),
        icon: <ExclamationCircleOutlined />,
        content: true,
        async onOk() {
          return new Promise<void>(async (resolve, reject) => {
            try {
              let deleteId = rId

              const result = await spuServices.deleteAdminUsers(deleteId.toString())

              const { error } = result.data
  
              if (error) {
                message.error(error.message)
  
                resolve()
              } else {
                message.success(t("supplierList.delete.success"))
  
                handleSearch()
                reject()
              }
            } catch (error) {
              reject()
            }
          }).catch(() => console.log('Oops errors!'))
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }

  return (
    <BaseLayout title={t("system.permissionsList")}>
      <PageHeader
        title={t("system.permissionsList")}
        style={{ padding: "16px 0" }}
        extra={[
          <Button key="new" type="primary" onClick={() => onHandleAdd()}>
            {t("skuList.action.add")}
          </Button>,
        ]}
      />

      <Card style={{ marginTop: 24 }}>
        <Table
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 325 }}
          pagination={{
            current: page,
            pageSize,
            total,
            onChange: handlePageChange,
          }}
          loading={loading}
        >
        </Table>
      </Card>

      <Drawer
        title={typeModal === 'add' ? t("speedChart.speed.add") : t("spuChart.tools.edit")}
        width={500}
        destroyOnClose
        placement="right"
        open={isModalVisible}
        onClose={hideIsModalVisible}
        extra={
          <Button 
            type="primary" 
            onClick={onSubmit}
          >
            {t("spuChart.Modify.submit")}
          </Button>
        }
      >
        {
          modalLoading && typeModal === 'edit' ? (
            <Spin/>
          ) : (
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Form.Item
                label={t("supplierList.columns.name")}
                required={true}
              >
                <Input 
                  style={{ width: 300 }} 
                  value={name}
                  onChange={(e) => handleInputChange('name', e)}
                />
              </Form.Item>
              <Form.Item
                label={t("crm.form.loginName")}
                required={true}
              >
                <Input 
                  style={{ width: 300 }}
                  value={account}
                  onChange={(e) => handleInputChange('account', e)}
                />
              </Form.Item>
              <Form.Item label={t("system.form.mobile")}>
                <Input 
                  style={{ width: 300 }}
                  value={mobile}
                  onChange={(e) => handleInputChange('mobile', e)}
                />
              </Form.Item>
              <Form.Item
                label={t("login.password.placeholder")}
                required={typeModal === 'add'}
              >
                <Input.Password 
                  style={{ width: 300 }}
                  value={password}
                  onChange={(e) => handleInputChange('password', e)}
                />
              </Form.Item>
              <Form.Item
                label={t("crm.form.passwordConfirmation")}
                required={typeModal === 'add'}
              >
                <Input.Password 
                  style={{ width: 300 }}
                  value={passwordConfirmation}
                  onChange={(e) => handleInputChange('passwordConfirmation', e)}
                />
              </Form.Item>
              <Form.Item 
                label={t("crm.form.region")}
                wrapperCol={{span: 2}}
              >
                <Checkbox 
                  indeterminate={indeterminate} 
                  onChange={onCheckAllChange} 
                  checked={checkAll} 
                  style={{width: 300}} 
                >
                  {t("fileManager.grid.selectAll")}
                </Checkbox>
                <CheckboxGroup 
                  options={plainOptions} 
                  value={checkedList} 
                  onChange={onChange} 
                  style={{width: 300}} 
                />
              </Form.Item>
              <Form.Item 
                label={t("crm.form.referenceResources")}
                wrapperCol={{span: 2}}
              >
                <Checkbox
                  indeterminate={indeterminateTwo} 
                  onChange={onCheckAllChangeTwo} 
                  checked={checkAllTwo} 
                  style={{width: 300}} 
                >
                  {t("fileManager.grid.selectAll")}
                </Checkbox>
                <CheckboxGroup 
                  options={plainOptionsTwo} 
                  value={checkedListTwo} 
                  onChange={onChangeTwo} 
                  style={{width: 300}} 
                />
              </Form.Item>
            </Form>
          )
        }
      </Drawer>
    </BaseLayout>
  )
}